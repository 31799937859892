























































import { Component, Mixins, Prop, Provide, Ref, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { httpClient } from '@/util/http-client';
import FServerFormChild from '@/components/form/f-server-form-child.vue';
import FServerFormWidgets from '@/components/form/f-server-form-widgets.vue';
import { cloneDeep, mapValues, omit } from 'lodash';
import {
    FormChild,
    FormChildren,
    FormChildType, FormChoiceChild,
    FormItemChoice,
    FormReference,
    FServerFormContext,
    getSelectedObjectFromChoices,
    InputValueTypes,
    isChoiceType, isCollectionType,
    OnSubmitSuccessHandler,
    OptionFilterFunc,
    ServerForm,
    ServerFormChildren,
    ServerFormModel,
    ServerFormRegistry,
    ServerFormValueObjects,
    ServerFormValues,
} from '@/components/form/serverForm';
import { AxiosError, AxiosResponse } from 'axios';
import FormErrors from '@/components/form-errors.vue';
import { FormErrorsInterface } from '@/components/form-error-list.vue';
import FServerFormLoader from '@/components/form/f-server-form-loader.vue';
import VisibilityAwareMixin from '@/components/ui/mixin/visibility-aware-mixin.vue';
import PlaceholderOptions from '@/components/form/placeholders';
import ServerFormContentCache from '@/components/form/formCache';
import UrlUtil from '@/util/url';
import { UIChangeTrigger } from '@/components/form/UIChangeTrigger';
import createObserver from '@/util/observer';
import mergeObject, { mergeArrays } from '@/util/mergeObject';
import foodEventBus from '@/module/shared/general/utils/eventBus';
import { createLazyLoadingStateEventForId, createSetLazyLoadedChoicesEventForId } from '@/components/form/events';


export type ValidationChangedEvent = { valid : boolean };
export type FormChildTriggerEvent = { trigger: UIChangeTrigger };

@Component({
    components: {
        FServerFormLoader,
        FServerFormChild,
        FServerFormWidgets,
        FormErrors,
    },
})
export default class FServerForm<K extends string = string> extends Mixins(TranslateMixin, VisibilityAwareMixin) {
    @Prop({ required: true })
    id!: string|FormReference;

    @Prop({ type: String, required: true })
    url!: string;

    @Prop({ type: Object, required: false, default: null })
    serverForm!: ServerForm|null;

    @Prop({ type: Boolean, default: false })
    hideLoader!: boolean;

    @Prop({ type: Boolean, default: false })
    disablePlaceholders!: boolean;

    @Prop({ type: Boolean, default: false })
    hideErrors!: boolean;

    @Prop({ type: Boolean, default: false })
    withSaveButton!: boolean;

    @Prop({ type: Boolean, default: false })
    disableCache!: boolean;

    @Prop({ type: Boolean, default: false })
    nativeSubmit!: boolean;

    @Prop({ type: Boolean, default: false })
    noCsrf!: boolean;

    @Prop({ type: Boolean, default: false })
    closeModalOnSubmit!: boolean;

    @Prop({ type: Boolean, default: false })
    disableInitialFocus!: boolean;

    @Prop({ required: false })
    onSuccess!: OnSubmitSuccessHandler<Record<string, unknown>>|undefined;

    @Prop({ type: [Object, String], default: () => '' })
    rules!: string | Record<string, unknown>;

    @Prop({ type: Object, default: () => null })
    childPlaceholderMap!: Partial<Record<FormChildType, PlaceholderOptions>>|null;

    @Ref()
    contentWrapper!: HTMLDivElement;

    // State
    form: ServerForm = {
        name: '',
        constraints: {},
        children: {},
        additionalData: {}
    };

    initialized = false;
    loading = false;
    values: ServerFormValues<K> = {} as ServerFormValues<K>;
    children: ServerFormChildren<K> = {} as ServerFormChildren<K>;
    valueObjects: ServerFormValueObjects = {};

    errorsRenderedExternally = false;
    serverErrors: FormErrorsInterface = {};

    rendered: Record<string, boolean> = {};

    validationScheme!: any;

    actualFilters: Record<string, OptionFilterFunc> = {};
    childChoices: Record<string, Array<FormItemChoice>> = {};
    childInitializedMap: Record<string, boolean> = {};
    cache = new ServerFormContentCache(this.idString);
    private initializedCallbacks = createObserver();
    private validationChangedCallbacks = createObserver<ValidationChangedEvent>();
    private formChildTriggerCallbacks = createObserver<FormChildTriggerEvent>();

    initialElementIsFocused = false;

    @Provide()
    formContext = this.createFormContext();

    private created(): void {
        ServerFormRegistry.register(this);

        if (!this.disableCache) {
            this.cache.loadCache();
        }
    }

    private beforeDestroy(): void {
        ServerFormRegistry.unregister(this);
    }

    private mounted(): void {
        if (this.isActive) {
            this.initializeForm();
        }
    }

    @Watch('url')
    async onUrlChange() {
        if (this.isActive) {
            this.loading = true;
            await this.initializeForm();
            this.loading = false;
        }
    }

    private async initializeForm(): Promise<void> {
        this.loading = true;

        let data: ServerForm;
        if (this.serverForm === null) {
            const urlObject = UrlUtil.createAbsoluteUrlObject(this.url);
            urlObject.searchParams.append('formData', '1');

            const response: AxiosResponse = await httpClient.get<ServerForm>(urlObject.toString());
            data = response.data;
        } else {
            data = this.serverForm;
        }

        this.children = data.children;
        this.values = mapValues(this.children, child => child.value) as ServerFormValues;

        this.children = omit(this.children, '_token');
        this.valueObjects = mapValues(this.children, child => isChoiceType(child) ? getSelectedObjectFromChoices(child.value, child.choices) : undefined);
        this.rendered = mapValues(this.children, () => false);
        this.childChoices = mapValues(this.children, child => isChoiceType(child) ? child.choices : []);

        this.form = data;
        this.$emit('initialized');
        this.emitModel();
        this.initialized = true;
        this.loading = false;

        if (!this.disableCache) {
            // we have to skip 2 frames to get the real height
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.initializedCallbacks.dispatchAll();
                    this.cache.persistCache(this.children, this.contentWrapper);
                });
            });
        }
    }

    async lazyLoadChoices(child: FormChoiceChild) {
        foodEventBus.publish(createLazyLoadingStateEventForId(this.children[child.name].id)());

        const urlObject = UrlUtil.createAbsoluteUrlObject(this.url);
        urlObject.searchParams.append('formData', '1');
        urlObject.searchParams.append('serverFormField', child.name);

        const response: AxiosResponse = await httpClient.get<ServerForm>(urlObject.toString());

        const data = response.data;

        if (isChoiceType(data)) {
            foodEventBus.publish(createSetLazyLoadedChoicesEventForId(this.children[child.name].id)({
                choices: data.choices
            }));

            child.choices = data.choices;
        }
    }

    private createFormContext(): FServerFormContext {
        return {
            placeholdersAreDisabled: () => this.disablePlaceholders,
            getChildPlaceholderMap: () => this.childPlaceholderMap,
            lazyLoadedChoices: (child: FormChoiceChild) => {
                this.lazyLoadChoices(child);
            },
            onInitialized: (callback: () => void) => {
                if (this.initialized)  {
                    callback();
                } else {
                    this.initializedCallbacks.observe(callback);
                }
            },
            onFormFieldValidationChange: (field: string, callback: (event: ValidationChangedEvent) => void) => {
                this.validationChangedCallbacks.observeForKey(field, callback);
            },
            onFormFieldTrigger: (field: string|FormChildren, callback: (event: FormChildTriggerEvent) => void) => {
                const childId = typeof field === 'string' ? this.formPrefix + field : field.id;
                this.formChildTriggerCallbacks.observeForKey(childId, callback);
            },
            initialized: () => this.initialized,
            initialElementIsFocused: () => this.disableInitialFocus || this.initialElementIsFocused,
            setInitialElementIsFocused: (focused: boolean) => { this.initialElementIsFocused = focused; },
            triggerFormChildChange: this.triggerFormChildChange.bind(this),
            getFormModel: <T extends string = string> () => this.formModel as ServerFormModel<T>,
            reloadForm: this.initializeForm.bind(this),
            registerChild: (childName: string) => {
                this.childInitializedMap[childName] = false;
            },
            childFinishedInitializing: (childName: string) => {
                this.childInitializedMap[childName] = true;

                // everything is initialized
                if (!Object.values(this.childInitializedMap).some((init) => !init)) {
                    this.$emit('fully-initialized');
                }
            }
        };
    }

    get self() {
        return this;
    }

    get idString(): string {
        return this.id instanceof FormReference ? this.id.getId() : this.id;
    }

    get contentIsLoading(): boolean {
        return !this.initialized && this.loading;
    }

    get formPrefix(): string {
        return this.form.name + '_';
    }

    get formModel(): ServerFormModel {
        return {
            values: this.values,
            childChoices: this.childChoices,
            valueObjects: this.valueObjects,
            additionalData: this.form.additionalData
        };
    }

    private emitModel(): void {
        this.$emit('input', this.formModel);
    }

    errorState(): FormErrorsInterface {
        this.errorsRenderedExternally = true;

        return this.serverErrors;
    }

    public triggerFormChildChange<T extends string = string>(field: T|FormChildren, trigger: UIChangeTrigger): void {
        const childId = typeof field === 'string' ? this.formPrefix + field : field.id;

        this.formChildTriggerCallbacks.dispatchByKey(childId, { trigger });
    }

    private formData() {
        return {
            [this.form.name]: this.getChildSubmitValues(this.values, this.children)
        };
    }

    private getChildSubmitValues(values: ServerFormValues, children: ServerFormChildren): Record<string, any> {
        const valuesToSubmit: Record<string, any> = {};

        for (const key in values) {
            if (key === '_token') {
                valuesToSubmit[key] = values[key];
                continue;
            }

            if (!(key in children)) {
                console.log(children);
                console.warn(`Key niet aanwezig in form children: '${key}'`);
            }

            const child: FormChildren|null = children[key] ?? null;

            if (child?.childType === FormChildType.COLLECTION || isCollectionType(child)) {
                valuesToSubmit[key] = this.getChildSubmitValues(values[key] as unknown as ServerFormValues, child.children!);
            } else {
                let isCheckbox = child?.childType === FormChildType.CHECKBOX;
                const isUncheckedCheckbox = isCheckbox && values[key] !== '1' && values[key] !== true;

                if (!isUncheckedCheckbox) {
                    valuesToSubmit[key] = isCheckbox ? 1 : values[key];
                }
            }
        }

        return valuesToSubmit;
    }

    public validate(): boolean {
        return true;
    }

    public handleSubmit<T extends Record<string, unknown> = Record<string, unknown>>(submitEvent: Event): Promise<T> {
        if (!this.nativeSubmit) {
            submitEvent?.preventDefault();
        } else {
            return new Promise(() => {});
        }

        if (!this.validate()) return Promise.reject();

        return this.submit<T>();
    }

    public async submit<T extends Record<string, unknown> = Record<string, unknown>>(): Promise<T> {
        this.serverErrors = {};
        this.loading = true;
        const { data } = await httpClient.post<T>(this.url, this.formData())
            .catch((error: AxiosError) => {
                this.serverErrors = error.response?.data.errors;
                this.loading = false;

                this.$noty(this.$t('An error occurred').toString(), 'error');

                this.$emit('invalid-form-data');

                return Promise.reject('Invalid form data');
            }).then(res => {
                return res;
            });

        this.$emit('submitted-form-data', this.values);
        this.$emit('submit', data);

        if (this.onSuccess) {
            const successResponse = this.onSuccess(data);

            if (successResponse instanceof Promise) {
                await successResponse;
            }
        }

        if (this.closeModalOnSubmit) {
            this.hideParentModal();
        }

        this.loading = false;

        return data;
    }

    private onValueChange(child: FormChild, value: InputValueTypes) {
        this.$emit(`change-${child.name}`, value);
        this.emitModel();
    }

    private getObjectValueForChild(child: FormChildren) {
        if (isChoiceType(child)) {
            return child.choices.find(choice => choice['value'] === child.value);
        }
        return undefined;
    }

    @Watch('isActive')
    onActive() {
        if (this.isActive && !this.initialized) {
            this.initializeForm();
        }
    }

    @Watch('serverErrors')
    onServerErrors() {
        if (Object.keys(this.serverErrors).length === 0) {
            this.validationChangedCallbacks.dispatchAll({ valid: true });
            return;
        }

        this.validationChangedCallbacks.dispatchByKeys(Object.keys(this.serverErrors), { valid: false });
    }

    @Watch('values', { deep: true })
    onChangeValues(values: ServerFormValues<K>): void {
        this.$emit('change', values);
    }
}
